#settingsWidget {
    position: fixed;
    top: 10%;
    right: 10%;
    width: 300px;
    height: 400px;
    background-color: #212121;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 20px;
    z-index: 1000; /* Ensure it appears on top */
    display: flex;
    flex-direction: column; /* Stack children vertically */
}

#settingsWidget select {
    background-color: #888888;
    border-radius: 4px; /* Rounded corners */
    color: #fff; /* White text for better contrast */
    margin-bottom: 5px;
}

#settingsWidget select:hover {
    background-color: #595959;
}

#settingsWidget button:first-child {
    margin-top: auto; /* Push the buttons to the bottom */
}

#settingsWidget button:last-child {
    margin-left: auto; /* Push the Close button to the right */
}

.settings-buttons {
    display: flex;
    justify-content: space-between; /* Space out the buttons */
    width: 100%; /* Take the full width of the parent */
}

#settingsWidget button {
    flex: 1; /* Allow the buttons to grow and take up equal space */
    margin: 5px; /* Some spacing around the buttons */
    color: white; /* Text color */
    height: 40px;
    background-color: #777777; /* Transparent background */
    border: 1px solid white; /* Optional: Add a border to make the button more visible */
    border-radius: 6px;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

#settingsWidget button:hover {
    background-color: #595959; /* Hover effect */
}

.form-group {
    display: flex;
    align-items: center; /* This ensures vertical alignment if your label and select have different heights */
    margin-bottom: 10px; /* Space between each form group */
}

.form-group label {
    width: 50px; /* This value can be adjusted based on your longest label */
    text-align: right; /* Right-align the text */
    margin-right: 10px; /* Space between the label and the select */
}

.checkbox-group {
    margin-bottom: 20px; /* Adjust this value to your preference */
}

#settingsWidget .button-container {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#settingsWidget .reset-collection-button {
    text-align: center;
}

#settingsWidget .reset-collection-button button {
    background-color: red;
    color: white;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid white; /* Optional: Add a border to make the button more visible */
}

#settingsWidget .reset-collection-button button:hover {
    background-color: darkred;
}

#settingsWidget .import-data-button {
    background-color: green;
    color: white;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid white;
    margin-bottom: 10px; /* Add some margin to separate it from the other buttons */
    width: auto; /* Reset the width */
    flex: none; /* Override the flex property */
}

#settingsWidget .import-data-button:hover {
    background-color: darkgreen;
}