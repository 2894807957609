.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.home-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
}

.line-separator-home {
    width: 1000px;
    height: 2px;
    background-color: white;
    margin-bottom: 30px;
}

.overall-progress {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.individual-progress-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    gap: 30px;
}