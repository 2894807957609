h3 {
    font-size: 24px;
    margin-bottom: -10px;
    color: rgb(255, 255, 255);
}

.subcategoryHeader {
    font-size: 16px;
    margin-bottom: 0px;
    color: rgb(255, 255, 255);
}

.items-row {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.item-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    background-size: calc(100% + 2px) calc(100% + 2px);
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid rgb(255, 0, 0);
}

.items-row a {
    text-decoration: none;
    display: inline-block;
}

.item-icon.obtained {
    border: 2px solid rgb(0, 255, 0);
}

.item-icon.obtained::after {
    content: '\f00c';  /* FontAwesome checkmark icon */
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(0, 255, 0);
    font-size: 32px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.icon-wrapper {
    position: relative;
    width: 36px;
    height: 36px;
}

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    padding: 8px;
    border-radius: 4px;
    pointer-events: none;
    z-index: 1000;
    max-width: 200px;
}

.line-separator-items {
    width: 1000px;
    height: 2px;
    background-color: white;
    margin: 20px auto; /* Center the line and give it some vertical space */
}

/* Container for all categories and items */
.categories-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1000px;
    margin: 0 auto; /* Center the container */
}

/* Each main category will be a block */
.category-grid {
    width: 100%; /* Take up full width of the parent container */
    margin-bottom: 20px; /* Space between categories */
}

/* Subcategories will be in a grid */
.subcategories-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
}