.home-progress-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 2px; /* Reduce this value to minimize the space between label and bar */
}

.home-progress-label {
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 5px;
    color: rgb(255, 255, 255);
    font-size: 16px;
}

.home-progress-bar {
    position: relative; /* Set to relative so we can position the text inside it */
    flex-grow: 1;
    height: 25px;
    width: 450px; /* Set a fixed width for the progress bar */
    background-color: #424242;
    border-radius: 15px;
    overflow: hidden;
    margin-top: -5px;
}

.overall .home-progress-bar {
    width: 550px;
    height: 30px;
}

.home-progress-bar-fill {
    height: 100%;
    background-color: #727272;
    transition: width 0.5s;
}

.home-progress-text {
    position: absolute; /* Position the text on top of the progress bar */
    top: 50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Adjust for the exact center */
    font-size: 12px; /* Adjust font size as needed */
    color: rgb(255, 255, 255);
}
