.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes at least the full height of the viewport */
}

/* Assuming you have a main content container, make it grow */
.main-content {
  flex: 1;
}
