.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212121; /* or any color you prefer */
    color: #fff;
    padding: 20px 0;
    margin-top: 60px;
    position: relative;
    bottom: 0;
    width: 100%;
    gap: 20px; /* space between links */
}

.footer a {
    color: #fff;
    text-decoration: none;
    padding: 0 10px;
}

.footer a:hover {
    text-decoration: underline;
}
