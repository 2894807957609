/* Navbar.css */
.navbar {
  background-color: #212121;
  height: 40px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center; /* Center the inner container */
}

.navbar-inner {
  display: flex;
  align-items: center;
  width: 1000px;
  padding: 0;
}

.logo-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex; /* Align logo and text horizontally */
  align-items: center; /* Center align logo and text vertically */
}

.logo-image {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.navbar-buttons {
  flex: 1; /* Take up remaining space */
  display: flex;
  justify-content: center; /* Center align buttons horizontally */
}

.navbar-button {
  background-color: #212121;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  text-decoration: none;
}

.navbar-button:hover {
  background-color: #777;
}

.login-button {
  color: white;
  text-decoration: none;
  margin-left: auto; /* Push the button to the right */
  margin-right: 20px; /* Add some right margin for spacing */
}
.red-btn {
  background-color: rgba(220, 53, 69, 0.7);
}

.red-btn:hover {
  background-color: rgba(220, 53, 69, 1);
}

.user-logout-holder {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  align-items: center; /* Align items vertically */
  width: 200px; /* Set a fixed width. Adjust this value as needed */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #212121;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 4px;
}

.dropdown-content a:hover {
  background-color: #777;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.icon-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px; /* Adjust as needed */
  margin: 0 10px; /* Adjust as needed */
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon-button:hover {
  color: #888; /* Adjust hover color as needed */
}

.username {
  text-transform: capitalize;
  margin: 10px;
}